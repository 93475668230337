<template>
    <div class="home">
        <div class="header">
            <Nav :active="0" :colorStyle="'#fff'" :logoPng="logo"/>
            <div class="text">
                <p>可配置指令执行系统综合服务商</p>
                <!-- <p>Intelligent manufacturing assistance systems</p> -->
            </div>
            <!-- <div class="oToT">
                01<p></p>03
            </div> -->
        </div>
        <div class="images">
            <div class="cp" :style="{width:cpWidth+'%'}" @mouseenter="cpEnterFn" @mouseleave="cpLeaveFn"></div>
            <div class="fa" :style="{width:faWidth+'%'}" @mouseenter="faEnterFn" @mouseleave="faLeaveFn"></div>
        </div>
        <Tab/>
    </div>
</template>

<script>
import Nav from "@/components/aNavBar"
import Tab from "@/components/aTabBar";
import "@/utils/flexible.js"
import logo from "@/assets/aHome/logo.png"
export default {
    components:{
        Nav,
        Tab,
    },
    data(){
        return{
            cpWidth:50,
            faWidth:50,
            logo,
        }
    },
    methods:{
        cpEnterFn(){
            this.cpWidth = 60;
            this.faWidth = 40;
        },
        cpLeaveFn(){
            this.cpWidth = 50;
            this.faWidth = 50;
        },
        faEnterFn(){
            this.cpWidth = 40;
            this.faWidth = 60;
        },
        faLeaveFn(){
            this.cpWidth = 50;
            this.faWidth = 50;
        }
    }
}
</script>

<style lang='scss' scoped>
.home{
    width: 100%;
    height: 100%;
    .header{
        width: 100%;
        height: 7.14rem;
        background-image: url('../assets/aHome/homeHead.png');
        background-size: cover;
        position: relative;
        .text{
            width: 10.54rem;
            height: 1.36rem;
            position: absolute;
            top: 2.63rem;
            left: 0;
            right: 0;
            margin: auto;
            & p:nth-child(1){
                font-size: 0.6rem;
                font-weight: 800;
                letter-spacing: 0.11rem;
                margin: 0;
                color: #fff;
            }
            & p:nth-child(2){
                font-size: 0.20rem;
                font-weight: 400;
                color: #fff;
            }
        }
        .oToT{
            width: 2.11rem;
            height: 0.22rem;
            color: #fff;
            position: absolute;
            top: 4.81rem;
            left: 0;
            right: 0;
            margin: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 0.16rem;
            p{
                width: 1.39rem;
                height: 1px;
                border: 1px dashed #979797;
                background-image: url('../assets/aHome/line.png');
                background-repeat: no-repeat;
            }
        }
    }
    .images{
        width: 100%;
        height: 7.16rem;
        display: flex;
        color: #fff;
        font-size: 0.33rem;
        .cp{
            width: 50%;
            height: 100%;
            transition: all .3s;
            /* background-color: rgba(255, 0, 0, 0.377); */
            line-height: 7.16rem;
            background-image: url("../assets/aHome/cpL.png");
            background-size: 100%;
            background-position: center center;
            cursor: pointer;
            /* background-repeat: no-repeat; */
            /* background-color: #081017; */
        }
        .fa{
            width: 50%;
            height: 100%;
            transition: all .3s;
            /* background-color: rgba(0, 0, 255, 0.315); */
            line-height: 7.16rem;
            background-image: url("../assets/aHome/faR.png");
            background-size: 100%;
            background-position: center center;
            cursor: pointer;
        }
    }
}
</style>