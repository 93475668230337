(function flexible(window, document) {
    var docEl = document.documentElement;
    console.log("clientWidth",docEl.clientWidth)
        var dpr = window.devicePixelRatio || 1;
        console.log("0000",dpr)
    

    // adjust body font size
    function setBodyFontSize() {
        if (document.body) {
            if(docEl.clientWidth>750){
                document.body.style.fontSize = 12.8 * dpr + "px";
            }else{
                document.body.style.fontSize = 8 * dpr + "px";
            }
            
        } else {
            document.addEventListener("DOMContentLoaded", setBodyFontSize);
        }
    }
    setBodyFontSize();

    // set 1rem = viewWidth / 19.2
    function setRemUnit() {
        var rem = docEl.clientWidth / 14.4;
        docEl.style.fontSize = rem + "px";
    }

    setRemUnit();

    // reset rem unit on page resize
    window.addEventListener("resize", setRemUnit);
    window.addEventListener("pageshow", function (e) {
        if (e.persisted) {
        setRemUnit();
        }
    });

    // detect 0.5px supports
    if (dpr >= 2) {
        var fakeBody = document.createElement("body");
        var testElement = document.createElement("div");
        testElement.style.border = ".5px solid transparent";
        fakeBody.appendChild(testElement);
        docEl.appendChild(fakeBody);
        if (testElement.offsetHeight === 1) {
        docEl.classList.add("hairlines");
        }
        docEl.removeChild(fakeBody);
    }
})(window, document);
  