<template>
    <div class="footer">
      <div class="top">
        
        <!-- <div class="solution">
          <div class="title">解决方案</div>
          <div class="line"></div>
          <p>石油化工</p>
          <p>钢结构</p>
          <p>建筑工程</p>
          <p>工程机械</p>
        </div> -->
        <div class="bulletin">
          <div class="title">新闻公告</div>
          <div class="line"></div>
          <p>公司新闻</p>
          <p>行业新闻</p>
        </div>
        <div class="aboutUs">
          <div class="title">关于我们</div>
          <div class="line"></div>
          <p>公司理念</p>
          <p>生态合作</p>
          <p>加入我们</p>
        </div>
        <div class="contactUs">
          <div class="title">联系我们</div>
          <div class="line"></div>
          <div class="hn">合肥总部</div>
          <div class="us">
            <img class="phone" src="@/assets/aHome/phone.png">
            <p>电话：(025)-52100047</p>
          </div>
          <div class="us">
            <img class="website" src="@/assets/home/website.png">
            <p>网址：www.ginyon.com</p>
          </div>
          <div class="us">
            <img class="address" src="@/assets/home/address.png">
            <p>公司地址：安徽省合肥市高新区中国声谷</p>
          </div>
          <div class="hn">南京公司</div>
          <div class="us">
            <img class="address" src="@/assets/home/address.png">
            <p>公司地址：江苏省南京市江宁区秣周东路12号4号楼</p>
          </div>
        </div>
        <div class="accounts">
          <div class="title">公众号</div>
          <div class="line"></div>
          <img src="@/assets/aHome/accounts.png">
        </div>
      </div>
      <div class="bottom">
        <!-- <img src="@/assets/home/ginyon.png"> -->
        <div class="befo">
            <div class="blac">
                <p>2022</p>
                <p>©</p>
                <p>景央科技</p>
            </div>
            <a class="icp" href="https://beian.miit.gov.cn">苏ICP备2022038262号-1</a>
        </div>
        <div class="aft">
            <img src="@/assets/home/hui.png">
            <a class="public" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32011502011700">苏公网安备 32011502011700</a>
        </div>
      </div>
    </div>
</template>

<script>
import "@/utils/flexible.js"
export default {

}
</script>

<style lang='scss' scoped>
  .footer{
    width: 100%;
    height: 3.70rem;
    background-color: #fff;
    .top{
      width: 100%;
      height: 3.00rem;
      padding-left: 2.44rem;
      padding-right: 1.79rem;
      padding-top: 0.76rem;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      text-align: left;
      .title{
        color: #000;
        font-size: 0.18rem;
      }
      .line{
        width: 0.16rem;
        height: 0.02rem;
        background: #000;
        border-radius: 1px;
        margin-top: 0.07rem;
        margin-bottom: 0.15rem;
      }
      .accounts{
        img{
          width: 1.17rem;
          height: 1.17rem;
        }
      }
      p{
        color: #666;
        font-size: 0.16rem;
        margin-top: 0;
        margin-bottom: 0.08rem;
      }
      .contactUs{
        .hn{
          font-size: 0.16rem;
          font-weight: 600;
          margin-bottom: 0.1rem;
          color: #9E2E24;
        }
        .us{
          display: flex;
          align-items: center;
          margin-bottom: 0.1rem;
          img{
            margin-right: 0.08rem;
            /* margin-top: 0.03rem; */
            width: 0.15rem;
            object-fit: contain;
          }
          p{
            width: 3.63rem;
            line-height: 0.2rem;
            font-size: 0.16rem;
            margin: 0;
          }
          /* .phone , .website{
            width: 0.21rem;
            height: 0.21rem;
          }
          .address{
            width: 0.21rem;
            height: 0.25rem;
          } */
        }
      }
    }
    .bottom{
      width: 100%;
      height: 0.70rem;
      padding-left: 5.50rem;
      padding-right: 4.5rem;
      box-shadow: inset 0rem 1px 0.03rem 0rem rgba(215,215,215,0.13);
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      
      .befo{
        width: 2.6rem;
        height: 0.17rem;
        display: flex;
        align-items: center;
        font-size: 0.12rem;
        color: #999999;
        .blac{
            width: 0.9rem;
            height: 0.17rem;
            font-size: 0.12rem;
            display: flex;
            align-items: center;
            p{
                font-size: 0.12rem;
                line-height: 0.17rem;
            }
            & p:nth-child(2){
                color: #000;
                margin-left: 0.03rem;
                margin-right: 0.03rem;
            }
            & p:nth-child(3){
                color: #9E2E24;
            }
        }
        .icp{
            width: 1.5rem;
            height: 0.17rem;
            font-size: 0.12rem;
            color: #999999;
            cursor: pointer;
            /* position: absolute; */
            /* left: 6.84rem; */
            /* z-index: 3; */
        }
      }
      .aft{
          width: 1.83rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
        .public{
            font-size: 0.12rem;
            color: #999999;
        }
        img{
            width: 0.2rem;
            height: 0.2rem;
        }
      }
      
    }
  }
  @media screen and (max-width:750px){
      .footer{
        height: 4.2rem;
        .top{
          height: 3.4rem;
          padding-top: 0.4rem;
          .contactUs{
            .us{
              img{
                margin-top: 0.1rem;
              }
              p{
                width: 3.83rem;
              }
            }
          }
        }
        .bottom{
          padding-left: 4.0rem;
          padding-right: 3.05rem;
        }
      }
  }
</style>