<template>
    <div class="aNav">
        <img :src="logoPng">
        <div class="oul">
            <template v-for="(item , index) in list">
                <div :class="{active: active===index}" :key="index" :style="{color:active===index? '#000':colorStyle,}" @click="choose(item.route)">{{item.text}}</div>
            </template>
        </div>
        <div class="search">
            <el-input
                :style="{'--inputColor':colorStyle}"
                placeholder="搜索您想查找的内容"
                v-model="searchValue">
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
        </div>
    </div>
</template>

<script>
import logo from "@/assets/aHome/logoR.png"
import "@/utils/flexible.js"
export default {
    props:{
        active: {
			type: Number,
			default: 0
		},
        colorStyle:{
            type: String,
            default:'#fff'
        },
        logoPng:{
            type: String,
            default: logo,
        }
    },
    data(){
        return{
            list:[
                { text:'首页',route:'/', },
                { text:'产品',route:'/industry', },
                { text:'应用场景',route:'/solution', },
                { text:'关于景央',route:'/about', },
            ],
            searchValue:'',
        }
    },
    methods:{
        // 选择
        choose(route){
            console.log(route);
            if(this.$route.path !== route){
                this.$router.push({path:route})
            }
            
        }
    }
}
</script>

<style lang='scss' scoped>
.aNav{
    width: 100%;
    height: 0.72rem;
    position: relative;
    img{
        width: 1.51rem;
        height: 0.29rem;
        position: absolute;
        left: 1.78rem;
        top: 0.19rem;
    }
    .oul{
        width: 3.65rem;
        height: 100%;
        position: absolute;
        left: 4.05rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.16rem;
        div{
            cursor: pointer;
            font-family: "黑体";
        }
        .active{
            color: #9E2E24!important;
            /* text-decoration: underline; */
        }
    }
    .search{
        width: 3rem;
        height: 0.4rem;
        position: absolute;
        right: 1.8rem;
        top: 0;
        bottom: 0;
        margin: auto;
        ::v-deep .el-input__inner{
            border-color: var(--inputColor);
            background-color: transparent;
            border-radius: 0;
            color: var(--inputColor);
            &::placeholder{
                color: var(--inputColor);
            }
        }
        ::v-deep .el-input__icon{
            color: var(--inputColor);
        }
    }
}
</style>